import React from 'react';
import {_t} from "../../../../js/translation";
import _ from "lodash";

class QuestionTextField extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const question = this.props.question;
        const index = this.props.index;

        return (
            <React.Fragment>
                <label className='label'>
                    {question.label} {question.required ? '*' : null}
                </label>
                <input onChange={this.props.onChange} required={question.required} defaultValue={question.value} type={'text'} data-uuid={question.uuid || question.id} name={`answers[${index}]`} className="form-control"/>
                {this.props.errors && this.props.errors.hasOwnProperty('errors') && this.props.errors.errors.children.hasOwnProperty('answers') ?
                    <small className="form-error">{_t(this.props.errors.errors.children.answers.children[index].children.value.errors)}</small> : null}

                {this.props.errors && !_.isEmpty(this.props.errors[question.uuid || question.id]) && (
                    <small className="form-error">{_t(this.props.errors[question.uuid || question.id])}</small>
                )}
            </React.Fragment>
        );
    }
}

export default QuestionTextField;
