import React from "react";
import {_t} from "../../../../js/translation";
import _ from "lodash";

class QuestionCheckboxField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        checked: !(!props.question.value || props.question.value === 'No')
    };

    this.handleCheck = this.handleCheck.bind(this);
  }

  handleCheck(e) {
    this.props.onChange(e);
    this.setState({checked: !this.state.checked});
  }

  render() {
    const question = this.props.question;
    const index = this.props.index;

    return (
      <React.Fragment>
          <div className={'one-row form-group__checkbox'}>
            <input
              required={question.required}
              type="checkbox"
              checked={this.state.checked}
              value={!this.state.checked ? 1 : 0}
              onChange={this.handleCheck}
              id={`answers[${index}]`}
              data-uuid={question.uuid || question.id}
              name={`answers[${index}]`}
            />
            <span className="custom-checkbox"><i className="ico"></i></span>
            <label htmlFor={`answers[${index}]`} className={'label'}>
              {question.label} {question.required ? "*" : null}
            </label>
          </div>
        {this.props.errors &&
        this.props.errors.hasOwnProperty("errors") &&
        this.props.errors.errors.children.hasOwnProperty("answers") ? (

          <small className="form-error">
            {_t(this.props.errors.errors.children.answers.children[index].children.value.errors)}
          </small>
        ) : null}
        {this.props.errors && !_.isEmpty(this.props.errors[question.uuid || question.id]) && (
            <small className="form-error">{_t(this.props.errors[question.uuid || question.id])}</small>
        )}
      </React.Fragment>
    );
  }
}

export default QuestionCheckboxField;
