import React, {forwardRef} from 'react';
import {_t} from "../../../../js/translation";
import Select from "react-select";
import * as _ from "lodash";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Button, Grid, GridItem, Icon} from "../../ui-kit";
import SelectFieldInput from "../select.field.input";
import {isInclusivelyBeforeDay, SingleDatePicker} from "react-dates";
import Config from "../../config";
import getUserDateFormat from "../../tools/getUserDateFormat";
import convertPHPDateFormat from "../../tools/convertPHPDateFormat";

class QuestionDateField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date: null,
            year: null,
            month: null,
            day: null,
            isFocusedScheduledAtDatePicker: false
        };

        if (props.question.value) {
            let date = moment(props.question.value);
            this.state.date = date.format('YYYY-MM-DD');
            this.state.year = date.format('YYYY');
            this.state.month = date.format('M');
            this.state.day = date.format('D');
        }
    }

    onYearChange(year) {
        this.onDateChange(year, this.state.month, this.state.day);
    }

    onMonthChange(month) {
        this.onDateChange(this.state.year, month, this.state.day);
    }

    onDayChange(day) {
        this.onDateChange(this.state.year, this.state.month, day);
    }

    onChangeDatePicker(date) {
        this.setDate(moment(date));
    }

    onDateChange(year, month, day) {
        this.setState({
            year: year,
            month: month,
            day: day,
        });

        if (year && month && day) {
            let date = moment(new Date(year, month - 1, day));
            this.setDate(date);
        }
    }

    setDate(date) {
        this.props.onChange(this.props.question.uuid || this.props.question.id, date.format('YYYY-MM-DD'));
        this.setState({
            year: date.format('YYYY'),
            month: date.format('M'),
            day: date.format('D'),
            date: date.format('YYYY-MM-DD')
        });
    }

    onChangeFocusedScheduledAtDatePicker(focused) {
        this.setState({isFocusedScheduledAtDatePicker: focused});

    }

    render() {
        const monthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const question = this.props.question;
        const index = this.props.index;

        let dateFormat = this.props.isAdminBooking || Config?.user?.date_format ? getUserDateFormat() : convertPHPDateFormat(window.userDateFormat);
        let dateFormatLowerCase = dateFormat.toLowerCase();

        let dateOrder = ['d', 'm', 'y'].sort((a, b) => dateFormatLowerCase.indexOf(a) - dateFormatLowerCase.indexOf(b));

        return (
            <React.Fragment>
                <label className='label'>
                    {question.label} {question.required ? '*' : null}
                </label>

                <Grid align={Grid.ALIGN.CENTER} direction={Grid.DIRECTION.ROW} className={'custom-date-field'}>
                    {dateOrder.map((value, key) => {
                        switch (value) {
                            case 'y':
                                return <GridItem key={key} maxItemWidth={'86px'}>
                                    <Select
                                        components={{Input: SelectFieldInput}}
                                        required={question.required}
                                        placeholder={'Year'}
                                        value={this.state.year ? {value: this.state.year, label: this.state.year} : null}
                                        isSearchable={true}
                                        options={_.reverse(_.range(1900, 2051)).map((value) => ({
                                            label: value,
                                            value: value
                                        }))}
                                        classNamePrefix={'react-select'}
                                        onChange={(value) => this.onYearChange(value.value)}
                                    />
                                </GridItem>;
                            case 'm':
                                return <GridItem key={key}>
                                    <Select
                                        components={{Input: SelectFieldInput}}
                                        required={question.required}
                                        placeholder={'Month'}
                                        value={this.state.month ? {value: this.state.month, label: _t(monthList[this.state.month - 1])} : null}
                                        isSearchable={true}
                                        options={_.range(1, 13).map((value) => ({
                                            label: _t(monthList[value - 1]),
                                            value: value
                                        }))}
                                        classNamePrefix={'react-select'}
                                        onChange={(value) => this.onMonthChange(value.value)}
                                    />
                                </GridItem>;
                            case 'd':
                                return <GridItem key={key} maxItemWidth={'75px'}>
                                    <Select
                                        components={{Input: SelectFieldInput}}
                                        required={question.required}
                                        placeholder={'Day'}
                                        value={this.state.day ? {value: this.state.day, label: this.state.day} : null}
                                        isSearchable={true}
                                        options={_.range(1, 32).map((value) => ({
                                            label: value,
                                            value: value
                                        }))}
                                        classNamePrefix={'react-select'}
                                        onChange={(value) => this.onDayChange(value.value)}
                                    />
                                </GridItem>;
                        }
                    })}

                    {this.props.isAdminBooking
                        ? <React.Fragment>
                            <Button template={Button.TEMPLATE.SECONDARY_OUTLINE} icon={Icon.ICON.CALENDAR} onClick={() => this.onChangeFocusedScheduledAtDatePicker(!this.state.isFocusedScheduledAtDatePicker)}/>

                            <SingleDatePicker
                                onDateChange={this.onChangeDatePicker.bind(this)}
                                focused={this.state.isFocusedScheduledAtDatePicker}
                                onFocusChange={({focused}) => this.onChangeFocusedScheduledAtDatePicker(focused)}
                                displayFormat={dateFormat}
                                daySize={36}
                                hideKeyboardShortcutsPanel={true}
                                numberOfMonths={1}
                                isOutsideRange={isInclusivelyBeforeDay}
                                firstDayOfWeek={Config.user.sunday_is_first_day ? 0 : 1}
                            />
                        </React.Fragment>
                        : <DatePicker
                            selected={this.state.date ? new Date(this.state.date) : null}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            customInput={<DatePickerFieldCustomInput/>}
                            onChange={this.onChangeDatePicker.bind(this)}
                        />}

                </Grid>

                {this.props.errors && this.props.errors.hasOwnProperty('errors') && this.props.errors.errors.children.hasOwnProperty('answers') ?
                    <small className="form-error">{_t(this.props.errors.errors.children.answers.children[index].children.value.errors)}</small> : null}

                {this.props.errors && !_.isEmpty(this.props.errors[question.uuid || question.id]) && (
                    <small className="form-error">{_t(this.props.errors[question.uuid || question.id])}</small>
                )}
            </React.Fragment>
        );
    }
}

const DatePickerFieldCustomInput = forwardRef(({onClick}, ref) => (
    <i className="material-icons fal fa-calendar" onClick={onClick} ref={ref}></i>
));

export default QuestionDateField;
