import React from 'react';
import Select from 'react-select';
import {parseDate} from '../../common/utils';
import getUserTimeFormat from "../../app/common/tools/getUserTimeFormat";
import Config from "../../app/common/config";
import moment from "moment";
import getDefaultTimezone from "../../app/common/tools/getDefaultTimezone";

class TimezoneSelectField extends React.Component {
    constructor(props) {
        super(props);

        let userTimeFormat = moment.localeData().longDateFormat('LT');
        if (window.userTimeFormat && window.userTimeFormat !== 'auto') {
            userTimeFormat = getUserTimeFormat(window.userTimeFormat);
        } else if (Config.user.time_format && Config.user.time_format !== 'auto') {
            userTimeFormat = getUserTimeFormat();
        }

        let defaultTimezone = getDefaultTimezone();

        this.state = {
            selectedOption: {value: defaultTimezone, label: defaultTimezone},
            date: new Date(),
            userTimeFormat: userTimeFormat,
            timezones: window.timezones || Config.timezones
        };

        this.handleChange = this.handleChange.bind(this);
        this.props.selectTimezone(defaultTimezone);
    }

    handleChange(selectedOption) {
        this.setState({selectedOption: selectedOption});

        if (selectedOption) {
            this.props.selectTimezone(selectedOption.value);
            this.props.fetchItems();
            this.props.clearSelectedTime();
        }
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            date: new Date()
        });
    }

    render() {
        let {selectedOption, userTimeFormat, timezones} = this.state;

        timezones = timezones.map(item => {
            let time = parseDate(this.state.date).tz(item).format(userTimeFormat);
            return {value: item, label: item + ' (' + time + ')'};
        });

        return (
            <Select
                className="dropdown dropdown-toggle react-select-container"
                classNamePrefix="react-select"
                name="form-field-name"
                value={selectedOption}
                placeholder="Select timezone..."
                onChange={this.handleChange}
                options={timezones}
            />
        );
    }
}

export default TimezoneSelectField;
