import React from 'react';

class QuestionHTMLField extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const question = this.props.question;

        return (
            <div className="question__html_field">
                <label className='label'>
                    {question.label}
                </label>
            </div>
        );
    }
}

export default QuestionHTMLField;
