import qs from "qs";
import {tz} from "moment-timezone";

const getDefaultTimezone = () => {
    const query = qs.parse(window.location.search, {ignoreQueryPrefix: true});

    let timezone = query.timezone ? query.timezone : tz.guess();

    if (!timezone && typeof Intl !== 'undefined') {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    if (timezone === 'Europe/Kiev') {
        timezone = 'Europe/Kyiv';
    }

    const mapTimezones = {
        "America/Indianapolis": "America/Indiana/Indianapolis",
        "America/Knox": "America/Indiana/Knox",
        "America/Marengo": "America/Indiana/Marengo",
        "America/Petersburg": "America/Indiana/Petersburg",
        "America/Tell_City": "America/Indiana/Tell_City",
        "America/Vevay": "America/Indiana/Vevay",
        "America/Vincennes": "America/Indiana/Vincennes",
        "America/Winamac": "America/Indiana/Winamac",
        "America/Montreal": "America/Toronto",
        "Asia/Calcutta": "Asia/Kolkata",
        "America/Buenos_Aires": "America/Argentina/Buenos_Aires",
        "America/Catamarca": "America/Argentina/Catamarca",
        "America/Cordoba": "America/Argentina/Cordoba",
        "America/Jujuy": "America/Argentina/Jujuy",
        "America/La_Rioja": "America/Argentina/La_Rioja",
        "America/Mendoza": "America/Argentina/Mendoza",
        "America/Rio_Gallegos": "America/Argentina/Rio_Gallegos",
        "America/Salta": "America/Argentina/Salta",
        "America/San_Juan": "America/Argentina/San_Juan",
        "America/San_Luis": "America/Argentina/San_Luis",
        "America/Tucuman": "America/Argentina/Tucuman",
        "America/Ushuaia": "America/Argentina/Ushuaia"
    };

    if (timezone in mapTimezones) {
        timezone = mapTimezones[timezone];
    }

    if (!timezone) {
        timezone = 'UTC';
    }

    return timezone;
};

export default getDefaultTimezone;