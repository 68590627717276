import React from 'react';
import QuestionTextField from './QuestionTextField';
import QuestionTextAreaField from './QuestionTextAreaField';
import QuestionCheckboxField from './QuestionCheckboxField';
import QuestionSelectField from './QuestionSelectField';
import QuestionDateField from './QuestionDateField';
import Fieldset from "../../ui-kit/fieldset";
import Heading from "../../ui-kit/heading";
import classNames from "classnames";
import QuestionHTMLField from "./QuestionHTMLField";

class QuestionsForm extends React.Component {
    constructor(props) {
        super(props);
    }

    renderQuestionField(index, item, isAdminBooking) {
        switch (item.type) {
            case 'text':
                return <QuestionTextField
                    question={item}
                    index={index}
                    onChange={this.props.onChange}
                    errors={this.props.errors}
                />;
            case 'textarea':
                return <QuestionTextAreaField
                    question={item}
                    index={index}
                    onChange={this.props.onChange}
                    errors={this.props.errors}
                />;
            case 'checkbox':
                return <QuestionCheckboxField
                    question={item}
                    index={index}
                    onChange={this.props.onChange}
                    errors={this.props.errors}
                />;
            case 'select':
                return <QuestionSelectField
                    question={item}
                    index={index}
                    onChange={this.props.onSelectChange}
                    errors={this.props.errors}
                />;
            case 'date':
                return <QuestionDateField
                    question={item}
                    isAdminBooking={isAdminBooking}
                    index={index}
                    onChange={this.props.onSelectChange}
                    errors={this.props.errors}
                />;
            case 'html':
                return <QuestionHTMLField
                    question={item}
                    index={index}
                />;
        }
    }

    render() {
        const isAdminBooking = this.props.isAdminBooking;
        const questions = this.props.questions;

        return (
            <Fieldset title={this.props.title || 'Question(s)'}
                      titleType={Heading.TYPE.HEADING}
                      headingClassName={!isAdminBooking || !questions || questions.length === 0 ? 'd-none' : ''}
                      className={classNames('create-event--block create-event--question v1')}
            >
                {!questions ? null : questions.map((item, index) => (
                    <div className={classNames('form-group', 'form-group--' + item.type)} key={index}>
                        {this.renderQuestionField(index, item, isAdminBooking)}
                    </div>
                ))}
            </Fieldset>
        );
    }
}

export default QuestionsForm;
